import {
  ApiDataResponse,
  CategoryListDTO,
  CategoryListResponse,
  EventDTO,
  EventListDTO,
  EventResponse,
  FilteredEventListResponse,
  UpdateEventPricingRequest,
  UpdateEventRequest,
} from "@root/utils/dtos";
import { ApiTransport } from "./config/apiTransport";
import { DateRange, MapBounds, NumberRange, Pagination } from "@root/utils/types";
import { EventSearchFilters } from "@root/stores/eventPageStore";
import { Coordinate } from "@root/utils/entities";

export class EventService {
  private readonly _apiTransport: ApiTransport;

  constructor(sessionCookie?: string) {
    this._apiTransport = new ApiTransport(sessionCookie);
  }

  searchEvents = (
    page: number,
    address?: string,
    mileRadius?: number,
    ageRange?: NumberRange,
    dateRange?: DateRange,
    categories?: string[],
    mapBounds?: MapBounds,
    coordinate?: Coordinate
  ): FilteredEventListResponse => {
    const data = [`page=${page}`];

    if (mapBounds) {
      data.push(`neLat=${mapBounds.northEast.latitude}`);
      data.push(`neLng=${mapBounds.northEast.longitude}`);
      data.push(`swLat=${mapBounds.southWest.latitude}`);
      data.push(`swLng=${mapBounds.southWest.longitude}`);
    } else if (address) {
      data.push(`address=${encodeURIComponent(address)}`);
      if (mileRadius) data.push(`mileRadius=${mileRadius}`);
    } else if (coordinate) {
      data.push(`lat=${coordinate.latitude}`);
      data.push(`lng=${coordinate.longitude}`);
      if (mileRadius) data.push(`mileRadius=${mileRadius}`);
    }

    if (ageRange?.min) data.push(`minAge=${ageRange?.min}`);
    if (ageRange?.max) data.push(`maxAge=${ageRange?.max}`);
    if (dateRange?.start) data.push(`start=${dateRange?.start?.toISOString()}`);
    if (dateRange?.end) data.push(`end=${dateRange?.end?.toISOString()}`);
    if (categories && categories.length > 0)
      data.push(`categories=${categories.join("&categories=")}`);

    let qs = ``;
    if (data.length > 0) qs = `?${data.join("&")}`;

    return this._apiTransport.get<
      EventListDTO & { searchFilters: EventSearchFilters } & { pagination: Pagination }
    >(`/api/v1/events${qs}`);
  };

  getEvent = (id: string): EventResponse =>
    this._apiTransport.get<EventDTO>(`/api/v1/events/${id}`);

  getCalendarFile = (id: string): Promise<ApiDataResponse<Blob>> =>
    this._apiTransport.get<Blob>(`/api/v1/events/${id}/ics`);

  getCategories = (): CategoryListResponse =>
    this._apiTransport.get<CategoryListDTO>(`/api/v1/events/categories`);

  createEvent = (request: UpdateEventRequest): EventResponse =>
    this._apiTransport.post<EventDTO>(`/api/v1/events`, request);

  updateEvent = (id: string, request: UpdateEventRequest): EventResponse =>
    this._apiTransport.put<EventDTO>(`/api/v1/events/${id}`, request);

  updateEventBannerImage = (eventId: string, formData: FormData) =>
    this._apiTransport.postMultipart(
      `/api/v1/events/${eventId}/images/banner`,
      formData
    );

  cancelEvent = (id: string) =>
    this._apiTransport.delete(`/api/v1/events/${id}`);

  addPricing = (eventId: string, request: UpdateEventPricingRequest) =>
    this._apiTransport.post(`/api/v1/events/${eventId}/prices`, request);

  updatePricing = (
    eventId: string,
    pricingId: string,
    request: UpdateEventPricingRequest
  ) =>
    this._apiTransport.put(
      `/api/v1/events/${eventId}/prices/${pricingId}`,
      request
    );

  deletePricing = (eventId: string, pricingId: string) =>
    this._apiTransport.delete(`/api/v1/events/${eventId}/prices/${pricingId}`);
}

export const eventService = new EventService();
